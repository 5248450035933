@use 'sass:map';

$studysmarter-icon-font-fontvar: 'studysmarter-icon-font';

@font-face {
  font-family: $studysmarter-icon-font-fontvar;
  src:
    url('./studysmarter-icon-font.woff?a0ec5871c1682a1e1cf97fea07fcb717') format('woff'),
    url('./studysmarter-icon-font.woff2?a0ec5871c1682a1e1cf97fea07fcb717') format('woff2');
}

@mixin icon-font-style {
  font-family: $studysmarter-icon-font-fontvar !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-selector {
  &[class^='app-icon-'],
  &[class*=' app-icon-'] {
    @content;
  }
}

[class^='app-icon-'],
[class*=' app-icon-'],
.icon-font {
  @include icon-font-style;
}

$studysmarter-icon-font-map: (
  'add-circle-fill': '\f101',
  'add-circle': '\f102',
  'add-document': '\f103',
  'add-fc': '\f104',
  'add-note': '\f105',
  'add-text': '\f106',
  'add': '\f107',
  'adult-learner': '\f108',
  'ai-flashcards-subtle': '\f109',
  'ai-flashcards': '\f10a',
  'alert-circle-fill': '\f10b',
  'alert-circle': '\f10c',
  'alert-triangle': '\f10d',
  'align-center': '\f10e',
  'align-left': '\f10f',
  'align-right': '\f110',
  'align-vertical-bottom': '\f111',
  'align-vertical-center': '\f112',
  'align-vertical-top': '\f113',
  'alt-text': '\f114',
  'apple': '\f115',
  'archive': '\f116',
  'arrow-back': '\f117',
  'arrow-circle-broken-down': '\f118',
  'arrow-circle-broken-right': '\f119',
  'arrow-down': '\f11a',
  'arrow-downward': '\f11b',
  'arrow-forward': '\f11c',
  'arrow': '\f11d',
  'attachment': '\f11e',
  'auto-play': '\f11f',
  'badge': '\f120',
  'bank-note': '\f121',
  'bg': '\f122',
  'bold': '\f123',
  'book-magic': '\f124',
  'briefcase-fill': '\f125',
  'briefcase': '\f126',
  'bug': '\f127',
  'building': '\f128',
  'burger-menu': '\f129',
  'calendar': '\f12a',
  'camera': '\f12b',
  'caret-down': '\f12c',
  'caret-right': '\f12d',
  'chatbox-ellipsis': '\f12e',
  'check': '\f12f',
  'checkmark-circle-fill': '\f130',
  'checkmark-circle': '\f131',
  'chemistry-formula': '\f132',
  'chevron-back': '\f133',
  'chevron-down': '\f134',
  'chevron-forward': '\f135',
  'chevron-up': '\f136',
  'circle-fill': '\f137',
  'circle': '\f138',
  'clear-filter': '\f139',
  'clear-formatting': '\f13a',
  'clipper': '\f13b',
  'clock': '\f13c',
  'close': '\f13d',
  'cloud-done': '\f13e',
  'cloud-download': '\f13f',
  'cloud-error': '\f140',
  'cloud': '\f141',
  'code-view': '\f142',
  'collapse': '\f143',
  'college': '\f144',
  'color-palette': '\f145',
  'comment': '\f146',
  'communities': '\f147',
  'compass-fill': '\f148',
  'compass': '\f149',
  'continuous': '\f14a',
  'contract': '\f14b',
  'copy': '\f14c',
  'credit-card': '\f14d',
  'crop': '\f14e',
  'decrease-indent': '\f14f',
  'definition': '\f150',
  'degree': '\f151',
  'deselect': '\f152',
  'desktop': '\f153',
  'diver': '\f154',
  'document-check-mark': '\f155',
  'document-magic': '\f156',
  'documents-fill': '\f157',
  'documents': '\f158',
  'double-tap': '\f159',
  'download-circle': '\f15a',
  'download': '\f15b',
  'downloaded-fill': '\f15c',
  'downloaded': '\f15d',
  'drag-handle': '\f15e',
  'dropdown-menu': '\f15f',
  'edit': '\f160',
  'elaborate': '\f161',
  'elementary': '\f162',
  'ellipsis-horizontal-fill': '\f163',
  'ellipsis-vertical-fill': '\f164',
  'eraser': '\f165',
  'exam-act': '\f166',
  'exam-ap': '\f167',
  'exam-aqa': '\f168',
  'exam-edxl': '\f169',
  'exam-gmat': '\f16a',
  'exam-gre': '\f16b',
  'exam-ib': '\f16c',
  'exam-ielts': '\f16d',
  'exam-lsat': '\f16e',
  'exam-mcat': '\f16f',
  'exam-ocr': '\f170',
  'exam-sat': '\f171',
  'exam-toefl': '\f172',
  'exam-wjec': '\f173',
  'exam': '\f174',
  'example-fill': '\f175',
  'example': '\f176',
  'excel': '\f177',
  'exercise': '\f178',
  'expand': '\f179',
  'explain-ai': '\f17a',
  'external-link': '\f17b',
  'fantasy-story': '\f17c',
  'fb': '\f17d',
  'fc-add-answer': '\f17e',
  'fc-add-question': '\f17f',
  'fc-answer-right': '\f180',
  'fc-anwer-wrong': '\f181',
  'fc-question': '\f182',
  'fc-remove-answer': '\f183',
  'fc-remove-question': '\f184',
  'feedback': '\f185',
  'figure': '\f186',
  'filters': '\f187',
  'flag': '\f188',
  'flash-off': '\f189',
  'flash': '\f18a',
  'flashcards': '\f18b',
  'folder-plus': '\f18c',
  'folder': '\f18d',
  'follow-fill': '\f18e',
  'follow': '\f18f',
  'font-family': '\f190',
  'footnote': '\f191',
  'formula': '\f192',
  'frame-size': '\f193',
  'freehand': '\f194',
  'funnel': '\f195',
  'gift-fill': '\f196',
  'gift': '\f197',
  'glasses': '\f198',
  'globe': '\f199',
  'goal-card-create': '\f19a',
  'goal-cards': '\f19b',
  'goal-slides': '\f19c',
  'goal-summary': '\f19d',
  'goals-dart': '\f19e',
  'goals': '\f19f',
  'google': '\f1a0',
  'grades': '\f1a1',
  'graduation-hat': '\f1a2',
  'grid': '\f1a3',
  'group-fill': '\f1a4',
  'group': '\f1a5',
  'hand-right': '\f1a6',
  'help-buoy': '\f1a7',
  'help': '\f1a8',
  'hide': '\f1a9',
  'highlight': '\f1aa',
  'highschool': '\f1ab',
  'hint': '\f1ac',
  'home-fill': '\f1ad',
  'home': '\f1ae',
  'horizontal-scroll': '\f1af',
  'hourglass': '\f1b0',
  'humor': '\f1b1',
  'image-03': '\f1b2',
  'image-border': '\f1b3',
  'image-caption': '\f1b4',
  'image': '\f1b5',
  'increase-indent': '\f1b6',
  'information-circle': '\f1b7',
  'invite-friend-fill': '\f1b8',
  'italic': '\f1b9',
  'key-takeaways': '\f1ba',
  'key': '\f1bb',
  'keyboard': '\f1bc',
  'language': '\f1bd',
  'laptop': '\f1be',
  'library-fill': '\f1bf',
  'library': '\f1c0',
  'lightbulb': '\f1c1',
  'line-height': '\f1c2',
  'line': '\f1c3',
  'link-broken': '\f1c4',
  'link-magic': '\f1c5',
  'link': '\f1c6',
  'list-menu': '\f1c7',
  'list-ordered': '\f1c8',
  'list-unordered': '\f1c9',
  'location': '\f1ca',
  'lock-fill': '\f1cb',
  'lock': '\f1cc',
  'log-out': '\f1cd',
  'logo': '\f1ce',
  'magic-marker': '\f1cf',
  'mail': '\f1d0',
  'menu': '\f1d1',
  'metaphors': '\f1d2',
  'microphone': '\f1d3',
  'mobile-to-webapp': '\f1d4',
  'monitor': '\f1d5',
  'move-flashcard': '\f1d6',
  'mute-fill': '\f1d7',
  'mute': '\f1d8',
  'no-ads': '\f1d9',
  'none': '\f1da',
  'note-thumbnail': '\f1db',
  'notifications-fill-active': '\f1dc',
  'notifications': '\f1dd',
  'offline': '\f1de',
  'options': '\f1df',
  'overview-fill': '\f1e0',
  'overview': '\f1e1',
  'package': '\f1e2',
  'page-move': '\f1e3',
  'pages': '\f1e4',
  'panel-left': '\f1e5',
  'panel-right': '\f1e6',
  'pause': '\f1e7',
  'percent': '\f1e8',
  'phone': '\f1e9',
  'pin-fill': '\f1ea',
  'pin': '\f1eb',
  'play-fill': '\f1ec',
  'play': '\f1ed',
  'poem': '\f1ee',
  'premium': '\f1ef',
  'print': '\f1f0',
  'progress': '\f1f1',
  'public-fill': '\f1f2',
  'public': '\f1f3',
  'quiz': '\f1f4',
  'quote-fill': '\f1f5',
  'quote': '\f1f6',
  'rating': '\f1f7',
  'rectangle': '\f1f8',
  'redirect': '\f1f9',
  'redo': '\f1fa',
  'referral': '\f1fb',
  'refresh': '\f1fc',
  'remove-note': '\f1fd',
  'remove': '\f1fe',
  'report': '\f1ff',
  'reveal': '\f200',
  'rotate-image-forward': '\f201',
  'rotate': '\f202',
  'sale-fill': '\f203',
  'sale': '\f204',
  'save-to-set': '\f205',
  'save': '\f206',
  'school-secondary': '\f207',
  'search-fill': '\f208',
  'search': '\f209',
  'send-fill': '\f20a',
  'send': '\f20b',
  'settings': '\f20c',
  'share-android': '\f20d',
  'share-ios': '\f20e',
  'share': '\f20f',
  'shield-tick': '\f210',
  'simplify': '\f211',
  'slash-circle': '\f212',
  'slide-in': '\f213',
  'slide-out': '\f214',
  'smartphone': '\f215',
  'smiley-bad': '\f216',
  'smiley-good': '\f217',
  'smiley-medium': '\f218',
  'smiley-very-well': '\f219',
  'sort': '\f21a',
  'spaced-repetition': '\f21b',
  'sparkles-filled': '\f21c',
  'sparkles': '\f21d',
  'special-character': '\f21e',
  'star-fill': '\f21f',
  'star-half-fill': '\f220',
  'star': '\f221',
  'statistics-fill': '\f222',
  'statistics': '\f223',
  'step-back': '\f224',
  'stop-circle': '\f225',
  'strikethrough': '\f226',
  'structure': '\f227',
  'subject-agriculture': '\f228',
  'subject-anthropology': '\f229',
  'subject-architecture': '\f22a',
  'subject-arts': '\f22b',
  'subject-biology': '\f22c',
  'subject-business': '\f22d',
  'subject-chemistry': '\f22e',
  'subject-computer-sciences': '\f22f',
  'subject-earth-sciences': '\f230',
  'subject-economics': '\f231',
  'subject-education': '\f232',
  'subject-engineering-technology': '\f233',
  'subject-english': '\f234',
  'subject-environmental-studies-forestry': '\f235',
  'subject-family-consumer-science': '\f236',
  'subject-fill': '\f237',
  'subject-french': '\f238',
  'subject-geography': '\f239',
  'subject-german': '\f23a',
  'subject-history': '\f23b',
  'subject-human-physical-performance-recreation': '\f23c',
  'subject-interdisciplinary-studies': '\f23d',
  'subject-journalism-media-studies-communication': '\f23e',
  'subject-languages-cultures': '\f23f',
  'subject-latin': '\f240',
  'subject-law': '\f241',
  'subject-library-museum-studies': '\f242',
  'subject-literature': '\f243',
  'subject-logic': '\f244',
  'subject-mathematics': '\f245',
  'subject-medicine': '\f246',
  'subject-military-sciences': '\f247',
  'subject-music': '\f248',
  'subject-other': '\f249',
  'subject-philosophy': '\f24a',
  'subject-physics': '\f24b',
  'subject-political-sciences': '\f24c',
  'subject-psychology': '\f24d',
  'subject-public-administration': '\f24e',
  'subject-religion-divinity': '\f24f',
  'subject-social-work': '\f250',
  'subject-sociology': '\f251',
  'subject-space-sciences': '\f252',
  'subject-spanish': '\f253',
  'subject-sports': '\f254',
  'subject-systems-sciences': '\f255',
  'subject-transportation': '\f256',
  'subject': '\f257',
  'subscript': '\f258',
  'summaries-fill': '\f259',
  'summaries': '\f25a',
  'superscript': '\f25b',
  'swipe-left': '\f25c',
  'swipe-right': '\f25d',
  'switch-horizontal': '\f25e',
  'table-cell-option': '\f25f',
  'table-cell-style': '\f260',
  'table-column': '\f261',
  'table-default': '\f262',
  'table-footer': '\f263',
  'table-header': '\f264',
  'table-row': '\f265',
  'table-style': '\f266',
  'tablet': '\f267',
  'tag': '\f268',
  'tags': '\f269',
  'tap': '\f26a',
  'teacher': '\f26b',
  'text-color': '\f26c',
  'text-magic': '\f26d',
  'text-size': '\f26e',
  'text': '\f26f',
  'textbooks': '\f270',
  'thumbs-down': '\f271',
  'thumbs-up': '\f272',
  'time': '\f273',
  'to-download': '\f274',
  'todo': '\f275',
  'topic-magic': '\f276',
  'touch-and-hold': '\f277',
  'trash': '\f278',
  'trello': '\f279',
  'trending-up': '\f27a',
  'trophies': '\f27b',
  'underline': '\f27c',
  'undo': '\f27d',
  'unfollow': '\f27e',
  'university': '\f27f',
  'unlock-fill': '\f280',
  'unlock': '\f281',
  'unpin': '\f282',
  'upload': '\f283',
  'user-fill': '\f284',
  'user': '\f285',
  'vertical-scroll': '\f286',
  'video-game': '\f287',
  'video': '\f288',
  'volume-fill': '\f289',
  'volume': '\f28a',
  'wallet': '\f28b',
  'zoom-in-gesture': '\f28c',
  'zoom-in': '\f28d',
  'zoom-out-gesture': '\f28e',
  'zoom-out': '\f28f',
);

.app-icon-add-circle-fill:before {
  content: map.get($studysmarter-icon-font-map, 'add-circle-fill');
}
.app-icon-add-circle:before {
  content: map.get($studysmarter-icon-font-map, 'add-circle');
}
.app-icon-add-document:before {
  content: map.get($studysmarter-icon-font-map, 'add-document');
}
.app-icon-add-fc:before {
  content: map.get($studysmarter-icon-font-map, 'add-fc');
}
.app-icon-add-note:before {
  content: map.get($studysmarter-icon-font-map, 'add-note');
}
.app-icon-add-text:before {
  content: map.get($studysmarter-icon-font-map, 'add-text');
}
.app-icon-add:before {
  content: map.get($studysmarter-icon-font-map, 'add');
}
.app-icon-adult-learner:before {
  content: map.get($studysmarter-icon-font-map, 'adult-learner');
}
.app-icon-ai-flashcards-subtle:before {
  content: map.get($studysmarter-icon-font-map, 'ai-flashcards-subtle');
}
.app-icon-ai-flashcards:before {
  content: map.get($studysmarter-icon-font-map, 'ai-flashcards');
}
.app-icon-alert-circle-fill:before {
  content: map.get($studysmarter-icon-font-map, 'alert-circle-fill');
}
.app-icon-alert-circle:before {
  content: map.get($studysmarter-icon-font-map, 'alert-circle');
}
.app-icon-alert-triangle:before {
  content: map.get($studysmarter-icon-font-map, 'alert-triangle');
}
.app-icon-align-center:before {
  content: map.get($studysmarter-icon-font-map, 'align-center');
}
.app-icon-align-left:before {
  content: map.get($studysmarter-icon-font-map, 'align-left');
}
.app-icon-align-right:before {
  content: map.get($studysmarter-icon-font-map, 'align-right');
}
.app-icon-align-vertical-bottom:before {
  content: map.get($studysmarter-icon-font-map, 'align-vertical-bottom');
}
.app-icon-align-vertical-center:before {
  content: map.get($studysmarter-icon-font-map, 'align-vertical-center');
}
.app-icon-align-vertical-top:before {
  content: map.get($studysmarter-icon-font-map, 'align-vertical-top');
}
.app-icon-alt-text:before {
  content: map.get($studysmarter-icon-font-map, 'alt-text');
}
.app-icon-apple:before {
  content: map.get($studysmarter-icon-font-map, 'apple');
}
.app-icon-archive:before {
  content: map.get($studysmarter-icon-font-map, 'archive');
}
.app-icon-arrow-back:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-back');
}
.app-icon-arrow-circle-broken-down:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-circle-broken-down');
}
.app-icon-arrow-circle-broken-right:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-circle-broken-right');
}
.app-icon-arrow-down:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-down');
}
.app-icon-arrow-downward:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-downward');
}
.app-icon-arrow-forward:before {
  content: map.get($studysmarter-icon-font-map, 'arrow-forward');
}
.app-icon-arrow:before {
  content: map.get($studysmarter-icon-font-map, 'arrow');
}
.app-icon-attachment:before {
  content: map.get($studysmarter-icon-font-map, 'attachment');
}
.app-icon-auto-play:before {
  content: map.get($studysmarter-icon-font-map, 'auto-play');
}
.app-icon-badge:before {
  content: map.get($studysmarter-icon-font-map, 'badge');
}
.app-icon-bank-note:before {
  content: map.get($studysmarter-icon-font-map, 'bank-note');
}
.app-icon-bg:before {
  content: map.get($studysmarter-icon-font-map, 'bg');
}
.app-icon-bold:before {
  content: map.get($studysmarter-icon-font-map, 'bold');
}
.app-icon-book-magic:before {
  content: map.get($studysmarter-icon-font-map, 'book-magic');
}
.app-icon-briefcase-fill:before {
  content: map.get($studysmarter-icon-font-map, 'briefcase-fill');
}
.app-icon-briefcase:before {
  content: map.get($studysmarter-icon-font-map, 'briefcase');
}
.app-icon-bug:before {
  content: map.get($studysmarter-icon-font-map, 'bug');
}
.app-icon-building:before {
  content: map.get($studysmarter-icon-font-map, 'building');
}
.app-icon-burger-menu:before {
  content: map.get($studysmarter-icon-font-map, 'burger-menu');
}
.app-icon-calendar:before {
  content: map.get($studysmarter-icon-font-map, 'calendar');
}
.app-icon-camera:before {
  content: map.get($studysmarter-icon-font-map, 'camera');
}
.app-icon-caret-down:before {
  content: map.get($studysmarter-icon-font-map, 'caret-down');
}
.app-icon-caret-right:before {
  content: map.get($studysmarter-icon-font-map, 'caret-right');
}
.app-icon-chatbox-ellipsis:before {
  content: map.get($studysmarter-icon-font-map, 'chatbox-ellipsis');
}
.app-icon-check:before {
  content: map.get($studysmarter-icon-font-map, 'check');
}
.app-icon-checkmark-circle-fill:before {
  content: map.get($studysmarter-icon-font-map, 'checkmark-circle-fill');
}
.app-icon-checkmark-circle:before {
  content: map.get($studysmarter-icon-font-map, 'checkmark-circle');
}
.app-icon-chemistry-formula:before {
  content: map.get($studysmarter-icon-font-map, 'chemistry-formula');
}
.app-icon-chevron-back:before {
  content: map.get($studysmarter-icon-font-map, 'chevron-back');
}
.app-icon-chevron-down:before {
  content: map.get($studysmarter-icon-font-map, 'chevron-down');
}
.app-icon-chevron-forward:before {
  content: map.get($studysmarter-icon-font-map, 'chevron-forward');
}
.app-icon-chevron-up:before {
  content: map.get($studysmarter-icon-font-map, 'chevron-up');
}
.app-icon-circle-fill:before {
  content: map.get($studysmarter-icon-font-map, 'circle-fill');
}
.app-icon-circle:before {
  content: map.get($studysmarter-icon-font-map, 'circle');
}
.app-icon-clear-filter:before {
  content: map.get($studysmarter-icon-font-map, 'clear-filter');
}
.app-icon-clear-formatting:before {
  content: map.get($studysmarter-icon-font-map, 'clear-formatting');
}
.app-icon-clipper:before {
  content: map.get($studysmarter-icon-font-map, 'clipper');
}
.app-icon-clock:before {
  content: map.get($studysmarter-icon-font-map, 'clock');
}
.app-icon-close:before {
  content: map.get($studysmarter-icon-font-map, 'close');
}
.app-icon-cloud-done:before {
  content: map.get($studysmarter-icon-font-map, 'cloud-done');
}
.app-icon-cloud-download:before {
  content: map.get($studysmarter-icon-font-map, 'cloud-download');
}
.app-icon-cloud-error:before {
  content: map.get($studysmarter-icon-font-map, 'cloud-error');
}
.app-icon-cloud:before {
  content: map.get($studysmarter-icon-font-map, 'cloud');
}
.app-icon-code-view:before {
  content: map.get($studysmarter-icon-font-map, 'code-view');
}
.app-icon-collapse:before {
  content: map.get($studysmarter-icon-font-map, 'collapse');
}
.app-icon-college:before {
  content: map.get($studysmarter-icon-font-map, 'college');
}
.app-icon-color-palette:before {
  content: map.get($studysmarter-icon-font-map, 'color-palette');
}
.app-icon-comment:before {
  content: map.get($studysmarter-icon-font-map, 'comment');
}
.app-icon-communities:before {
  content: map.get($studysmarter-icon-font-map, 'communities');
}
.app-icon-compass-fill:before {
  content: map.get($studysmarter-icon-font-map, 'compass-fill');
}
.app-icon-compass:before {
  content: map.get($studysmarter-icon-font-map, 'compass');
}
.app-icon-continuous:before {
  content: map.get($studysmarter-icon-font-map, 'continuous');
}
.app-icon-contract:before {
  content: map.get($studysmarter-icon-font-map, 'contract');
}
.app-icon-copy:before {
  content: map.get($studysmarter-icon-font-map, 'copy');
}
.app-icon-credit-card:before {
  content: map.get($studysmarter-icon-font-map, 'credit-card');
}
.app-icon-crop:before {
  content: map.get($studysmarter-icon-font-map, 'crop');
}
.app-icon-decrease-indent:before {
  content: map.get($studysmarter-icon-font-map, 'decrease-indent');
}
.app-icon-definition:before {
  content: map.get($studysmarter-icon-font-map, 'definition');
}
.app-icon-degree:before {
  content: map.get($studysmarter-icon-font-map, 'degree');
}
.app-icon-deselect:before {
  content: map.get($studysmarter-icon-font-map, 'deselect');
}
.app-icon-desktop:before {
  content: map.get($studysmarter-icon-font-map, 'desktop');
}
.app-icon-diver:before {
  content: map.get($studysmarter-icon-font-map, 'diver');
}
.app-icon-document-check-mark:before {
  content: map.get($studysmarter-icon-font-map, 'document-check-mark');
}
.app-icon-document-magic:before {
  content: map.get($studysmarter-icon-font-map, 'document-magic');
}
.app-icon-documents-fill:before {
  content: map.get($studysmarter-icon-font-map, 'documents-fill');
}
.app-icon-documents:before {
  content: map.get($studysmarter-icon-font-map, 'documents');
}
.app-icon-double-tap:before {
  content: map.get($studysmarter-icon-font-map, 'double-tap');
}
.app-icon-download-circle:before {
  content: map.get($studysmarter-icon-font-map, 'download-circle');
}
.app-icon-download:before {
  content: map.get($studysmarter-icon-font-map, 'download');
}
.app-icon-downloaded-fill:before {
  content: map.get($studysmarter-icon-font-map, 'downloaded-fill');
}
.app-icon-downloaded:before {
  content: map.get($studysmarter-icon-font-map, 'downloaded');
}
.app-icon-drag-handle:before {
  content: map.get($studysmarter-icon-font-map, 'drag-handle');
}
.app-icon-dropdown-menu:before {
  content: map.get($studysmarter-icon-font-map, 'dropdown-menu');
}
.app-icon-edit:before {
  content: map.get($studysmarter-icon-font-map, 'edit');
}
.app-icon-elaborate:before {
  content: map.get($studysmarter-icon-font-map, 'elaborate');
}
.app-icon-elementary:before {
  content: map.get($studysmarter-icon-font-map, 'elementary');
}
.app-icon-ellipsis-horizontal-fill:before {
  content: map.get($studysmarter-icon-font-map, 'ellipsis-horizontal-fill');
}
.app-icon-ellipsis-vertical-fill:before {
  content: map.get($studysmarter-icon-font-map, 'ellipsis-vertical-fill');
}
.app-icon-eraser:before {
  content: map.get($studysmarter-icon-font-map, 'eraser');
}
.app-icon-exam-act:before {
  content: map.get($studysmarter-icon-font-map, 'exam-act');
}
.app-icon-exam-ap:before {
  content: map.get($studysmarter-icon-font-map, 'exam-ap');
}
.app-icon-exam-aqa:before {
  content: map.get($studysmarter-icon-font-map, 'exam-aqa');
}
.app-icon-exam-edxl:before {
  content: map.get($studysmarter-icon-font-map, 'exam-edxl');
}
.app-icon-exam-gmat:before {
  content: map.get($studysmarter-icon-font-map, 'exam-gmat');
}
.app-icon-exam-gre:before {
  content: map.get($studysmarter-icon-font-map, 'exam-gre');
}
.app-icon-exam-ib:before {
  content: map.get($studysmarter-icon-font-map, 'exam-ib');
}
.app-icon-exam-ielts:before {
  content: map.get($studysmarter-icon-font-map, 'exam-ielts');
}
.app-icon-exam-lsat:before {
  content: map.get($studysmarter-icon-font-map, 'exam-lsat');
}
.app-icon-exam-mcat:before {
  content: map.get($studysmarter-icon-font-map, 'exam-mcat');
}
.app-icon-exam-ocr:before {
  content: map.get($studysmarter-icon-font-map, 'exam-ocr');
}
.app-icon-exam-sat:before {
  content: map.get($studysmarter-icon-font-map, 'exam-sat');
}
.app-icon-exam-toefl:before {
  content: map.get($studysmarter-icon-font-map, 'exam-toefl');
}
.app-icon-exam-wjec:before {
  content: map.get($studysmarter-icon-font-map, 'exam-wjec');
}
.app-icon-exam:before {
  content: map.get($studysmarter-icon-font-map, 'exam');
}
.app-icon-example-fill:before {
  content: map.get($studysmarter-icon-font-map, 'example-fill');
}
.app-icon-example:before {
  content: map.get($studysmarter-icon-font-map, 'example');
}
.app-icon-excel:before {
  content: map.get($studysmarter-icon-font-map, 'excel');
}
.app-icon-exercise:before {
  content: map.get($studysmarter-icon-font-map, 'exercise');
}
.app-icon-expand:before {
  content: map.get($studysmarter-icon-font-map, 'expand');
}
.app-icon-explain-ai:before {
  content: map.get($studysmarter-icon-font-map, 'explain-ai');
}
.app-icon-external-link:before {
  content: map.get($studysmarter-icon-font-map, 'external-link');
}
.app-icon-fantasy-story:before {
  content: map.get($studysmarter-icon-font-map, 'fantasy-story');
}
.app-icon-fb:before {
  content: map.get($studysmarter-icon-font-map, 'fb');
}
.app-icon-fc-add-answer:before {
  content: map.get($studysmarter-icon-font-map, 'fc-add-answer');
}
.app-icon-fc-add-question:before {
  content: map.get($studysmarter-icon-font-map, 'fc-add-question');
}
.app-icon-fc-answer-right:before {
  content: map.get($studysmarter-icon-font-map, 'fc-answer-right');
}
.app-icon-fc-anwer-wrong:before {
  content: map.get($studysmarter-icon-font-map, 'fc-anwer-wrong');
}
.app-icon-fc-question:before {
  content: map.get($studysmarter-icon-font-map, 'fc-question');
}
.app-icon-fc-remove-answer:before {
  content: map.get($studysmarter-icon-font-map, 'fc-remove-answer');
}
.app-icon-fc-remove-question:before {
  content: map.get($studysmarter-icon-font-map, 'fc-remove-question');
}
.app-icon-feedback:before {
  content: map.get($studysmarter-icon-font-map, 'feedback');
}
.app-icon-figure:before {
  content: map.get($studysmarter-icon-font-map, 'figure');
}
.app-icon-filters:before {
  content: map.get($studysmarter-icon-font-map, 'filters');
}
.app-icon-flag:before {
  content: map.get($studysmarter-icon-font-map, 'flag');
}
.app-icon-flash-off:before {
  content: map.get($studysmarter-icon-font-map, 'flash-off');
}
.app-icon-flash:before {
  content: map.get($studysmarter-icon-font-map, 'flash');
}
.app-icon-flashcards:before {
  content: map.get($studysmarter-icon-font-map, 'flashcards');
}
.app-icon-folder-plus:before {
  content: map.get($studysmarter-icon-font-map, 'folder-plus');
}
.app-icon-folder:before {
  content: map.get($studysmarter-icon-font-map, 'folder');
}
.app-icon-follow-fill:before {
  content: map.get($studysmarter-icon-font-map, 'follow-fill');
}
.app-icon-follow:before {
  content: map.get($studysmarter-icon-font-map, 'follow');
}
.app-icon-font-family:before {
  content: map.get($studysmarter-icon-font-map, 'font-family');
}
.app-icon-footnote:before {
  content: map.get($studysmarter-icon-font-map, 'footnote');
}
.app-icon-formula:before {
  content: map.get($studysmarter-icon-font-map, 'formula');
}
.app-icon-frame-size:before {
  content: map.get($studysmarter-icon-font-map, 'frame-size');
}
.app-icon-freehand:before {
  content: map.get($studysmarter-icon-font-map, 'freehand');
}
.app-icon-funnel:before {
  content: map.get($studysmarter-icon-font-map, 'funnel');
}
.app-icon-gift-fill:before {
  content: map.get($studysmarter-icon-font-map, 'gift-fill');
}
.app-icon-gift:before {
  content: map.get($studysmarter-icon-font-map, 'gift');
}
.app-icon-glasses:before {
  content: map.get($studysmarter-icon-font-map, 'glasses');
}
.app-icon-globe:before {
  content: map.get($studysmarter-icon-font-map, 'globe');
}
.app-icon-goal-card-create:before {
  content: map.get($studysmarter-icon-font-map, 'goal-card-create');
}
.app-icon-goal-cards:before {
  content: map.get($studysmarter-icon-font-map, 'goal-cards');
}
.app-icon-goal-slides:before {
  content: map.get($studysmarter-icon-font-map, 'goal-slides');
}
.app-icon-goal-summary:before {
  content: map.get($studysmarter-icon-font-map, 'goal-summary');
}
.app-icon-goals-dart:before {
  content: map.get($studysmarter-icon-font-map, 'goals-dart');
}
.app-icon-goals:before {
  content: map.get($studysmarter-icon-font-map, 'goals');
}
.app-icon-google:before {
  content: map.get($studysmarter-icon-font-map, 'google');
}
.app-icon-grades:before {
  content: map.get($studysmarter-icon-font-map, 'grades');
}
.app-icon-graduation-hat:before {
  content: map.get($studysmarter-icon-font-map, 'graduation-hat');
}
.app-icon-grid:before {
  content: map.get($studysmarter-icon-font-map, 'grid');
}
.app-icon-group-fill:before {
  content: map.get($studysmarter-icon-font-map, 'group-fill');
}
.app-icon-group:before {
  content: map.get($studysmarter-icon-font-map, 'group');
}
.app-icon-hand-right:before {
  content: map.get($studysmarter-icon-font-map, 'hand-right');
}
.app-icon-help-buoy:before {
  content: map.get($studysmarter-icon-font-map, 'help-buoy');
}
.app-icon-help:before {
  content: map.get($studysmarter-icon-font-map, 'help');
}
.app-icon-hide:before {
  content: map.get($studysmarter-icon-font-map, 'hide');
}
.app-icon-highlight:before {
  content: map.get($studysmarter-icon-font-map, 'highlight');
}
.app-icon-highschool:before {
  content: map.get($studysmarter-icon-font-map, 'highschool');
}
.app-icon-hint:before {
  content: map.get($studysmarter-icon-font-map, 'hint');
}
.app-icon-home-fill:before {
  content: map.get($studysmarter-icon-font-map, 'home-fill');
}
.app-icon-home:before {
  content: map.get($studysmarter-icon-font-map, 'home');
}
.app-icon-horizontal-scroll:before {
  content: map.get($studysmarter-icon-font-map, 'horizontal-scroll');
}
.app-icon-hourglass:before {
  content: map.get($studysmarter-icon-font-map, 'hourglass');
}
.app-icon-humor:before {
  content: map.get($studysmarter-icon-font-map, 'humor');
}
.app-icon-image-03:before {
  content: map.get($studysmarter-icon-font-map, 'image-03');
}
.app-icon-image-border:before {
  content: map.get($studysmarter-icon-font-map, 'image-border');
}
.app-icon-image-caption:before {
  content: map.get($studysmarter-icon-font-map, 'image-caption');
}
.app-icon-image:before {
  content: map.get($studysmarter-icon-font-map, 'image');
}
.app-icon-increase-indent:before {
  content: map.get($studysmarter-icon-font-map, 'increase-indent');
}
.app-icon-information-circle:before {
  content: map.get($studysmarter-icon-font-map, 'information-circle');
}
.app-icon-invite-friend-fill:before {
  content: map.get($studysmarter-icon-font-map, 'invite-friend-fill');
}
.app-icon-italic:before {
  content: map.get($studysmarter-icon-font-map, 'italic');
}
.app-icon-key-takeaways:before {
  content: map.get($studysmarter-icon-font-map, 'key-takeaways');
}
.app-icon-key:before {
  content: map.get($studysmarter-icon-font-map, 'key');
}
.app-icon-keyboard:before {
  content: map.get($studysmarter-icon-font-map, 'keyboard');
}
.app-icon-language:before {
  content: map.get($studysmarter-icon-font-map, 'language');
}
.app-icon-laptop:before {
  content: map.get($studysmarter-icon-font-map, 'laptop');
}
.app-icon-library-fill:before {
  content: map.get($studysmarter-icon-font-map, 'library-fill');
}
.app-icon-library:before {
  content: map.get($studysmarter-icon-font-map, 'library');
}
.app-icon-lightbulb:before {
  content: map.get($studysmarter-icon-font-map, 'lightbulb');
}
.app-icon-line-height:before {
  content: map.get($studysmarter-icon-font-map, 'line-height');
}
.app-icon-line:before {
  content: map.get($studysmarter-icon-font-map, 'line');
}
.app-icon-link-broken:before {
  content: map.get($studysmarter-icon-font-map, 'link-broken');
}
.app-icon-link-magic:before {
  content: map.get($studysmarter-icon-font-map, 'link-magic');
}
.app-icon-link:before {
  content: map.get($studysmarter-icon-font-map, 'link');
}
.app-icon-list-menu:before {
  content: map.get($studysmarter-icon-font-map, 'list-menu');
}
.app-icon-list-ordered:before {
  content: map.get($studysmarter-icon-font-map, 'list-ordered');
}
.app-icon-list-unordered:before {
  content: map.get($studysmarter-icon-font-map, 'list-unordered');
}
.app-icon-location:before {
  content: map.get($studysmarter-icon-font-map, 'location');
}
.app-icon-lock-fill:before {
  content: map.get($studysmarter-icon-font-map, 'lock-fill');
}
.app-icon-lock:before {
  content: map.get($studysmarter-icon-font-map, 'lock');
}
.app-icon-log-out:before {
  content: map.get($studysmarter-icon-font-map, 'log-out');
}
.app-icon-logo:before {
  content: map.get($studysmarter-icon-font-map, 'logo');
}
.app-icon-magic-marker:before {
  content: map.get($studysmarter-icon-font-map, 'magic-marker');
}
.app-icon-mail:before {
  content: map.get($studysmarter-icon-font-map, 'mail');
}
.app-icon-menu:before {
  content: map.get($studysmarter-icon-font-map, 'menu');
}
.app-icon-metaphors:before {
  content: map.get($studysmarter-icon-font-map, 'metaphors');
}
.app-icon-microphone:before {
  content: map.get($studysmarter-icon-font-map, 'microphone');
}
.app-icon-mobile-to-webapp:before {
  content: map.get($studysmarter-icon-font-map, 'mobile-to-webapp');
}
.app-icon-monitor:before {
  content: map.get($studysmarter-icon-font-map, 'monitor');
}
.app-icon-move-flashcard:before {
  content: map.get($studysmarter-icon-font-map, 'move-flashcard');
}
.app-icon-mute-fill:before {
  content: map.get($studysmarter-icon-font-map, 'mute-fill');
}
.app-icon-mute:before {
  content: map.get($studysmarter-icon-font-map, 'mute');
}
.app-icon-no-ads:before {
  content: map.get($studysmarter-icon-font-map, 'no-ads');
}
.app-icon-none:before {
  content: map.get($studysmarter-icon-font-map, 'none');
}
.app-icon-note-thumbnail:before {
  content: map.get($studysmarter-icon-font-map, 'note-thumbnail');
}
.app-icon-notifications-fill-active:before {
  content: map.get($studysmarter-icon-font-map, 'notifications-fill-active');
}
.app-icon-notifications:before {
  content: map.get($studysmarter-icon-font-map, 'notifications');
}
.app-icon-offline:before {
  content: map.get($studysmarter-icon-font-map, 'offline');
}
.app-icon-options:before {
  content: map.get($studysmarter-icon-font-map, 'options');
}
.app-icon-overview-fill:before {
  content: map.get($studysmarter-icon-font-map, 'overview-fill');
}
.app-icon-overview:before {
  content: map.get($studysmarter-icon-font-map, 'overview');
}
.app-icon-package:before {
  content: map.get($studysmarter-icon-font-map, 'package');
}
.app-icon-page-move:before {
  content: map.get($studysmarter-icon-font-map, 'page-move');
}
.app-icon-pages:before {
  content: map.get($studysmarter-icon-font-map, 'pages');
}
.app-icon-panel-left:before {
  content: map.get($studysmarter-icon-font-map, 'panel-left');
}
.app-icon-panel-right:before {
  content: map.get($studysmarter-icon-font-map, 'panel-right');
}
.app-icon-pause:before {
  content: map.get($studysmarter-icon-font-map, 'pause');
}
.app-icon-percent:before {
  content: map.get($studysmarter-icon-font-map, 'percent');
}
.app-icon-phone:before {
  content: map.get($studysmarter-icon-font-map, 'phone');
}
.app-icon-pin-fill:before {
  content: map.get($studysmarter-icon-font-map, 'pin-fill');
}
.app-icon-pin:before {
  content: map.get($studysmarter-icon-font-map, 'pin');
}
.app-icon-play-fill:before {
  content: map.get($studysmarter-icon-font-map, 'play-fill');
}
.app-icon-play:before {
  content: map.get($studysmarter-icon-font-map, 'play');
}
.app-icon-poem:before {
  content: map.get($studysmarter-icon-font-map, 'poem');
}
.app-icon-premium:before {
  content: map.get($studysmarter-icon-font-map, 'premium');
}
.app-icon-print:before {
  content: map.get($studysmarter-icon-font-map, 'print');
}
.app-icon-progress:before {
  content: map.get($studysmarter-icon-font-map, 'progress');
}
.app-icon-public-fill:before {
  content: map.get($studysmarter-icon-font-map, 'public-fill');
}
.app-icon-public:before {
  content: map.get($studysmarter-icon-font-map, 'public');
}
.app-icon-quiz:before {
  content: map.get($studysmarter-icon-font-map, 'quiz');
}
.app-icon-quote-fill:before {
  content: map.get($studysmarter-icon-font-map, 'quote-fill');
}
.app-icon-quote:before {
  content: map.get($studysmarter-icon-font-map, 'quote');
}
.app-icon-rating:before {
  content: map.get($studysmarter-icon-font-map, 'rating');
}
.app-icon-rectangle:before {
  content: map.get($studysmarter-icon-font-map, 'rectangle');
}
.app-icon-redirect:before {
  content: map.get($studysmarter-icon-font-map, 'redirect');
}
.app-icon-redo:before {
  content: map.get($studysmarter-icon-font-map, 'redo');
}
.app-icon-referral:before {
  content: map.get($studysmarter-icon-font-map, 'referral');
}
.app-icon-refresh:before {
  content: map.get($studysmarter-icon-font-map, 'refresh');
}
.app-icon-remove-note:before {
  content: map.get($studysmarter-icon-font-map, 'remove-note');
}
.app-icon-remove:before {
  content: map.get($studysmarter-icon-font-map, 'remove');
}
.app-icon-report:before {
  content: map.get($studysmarter-icon-font-map, 'report');
}
.app-icon-reveal:before {
  content: map.get($studysmarter-icon-font-map, 'reveal');
}
.app-icon-rotate-image-forward:before {
  content: map.get($studysmarter-icon-font-map, 'rotate-image-forward');
}
.app-icon-rotate:before {
  content: map.get($studysmarter-icon-font-map, 'rotate');
}
.app-icon-sale-fill:before {
  content: map.get($studysmarter-icon-font-map, 'sale-fill');
}
.app-icon-sale:before {
  content: map.get($studysmarter-icon-font-map, 'sale');
}
.app-icon-save-to-set:before {
  content: map.get($studysmarter-icon-font-map, 'save-to-set');
}
.app-icon-save:before {
  content: map.get($studysmarter-icon-font-map, 'save');
}
.app-icon-school-secondary:before {
  content: map.get($studysmarter-icon-font-map, 'school-secondary');
}
.app-icon-search-fill:before {
  content: map.get($studysmarter-icon-font-map, 'search-fill');
}
.app-icon-search:before {
  content: map.get($studysmarter-icon-font-map, 'search');
}
.app-icon-send-fill:before {
  content: map.get($studysmarter-icon-font-map, 'send-fill');
}
.app-icon-send:before {
  content: map.get($studysmarter-icon-font-map, 'send');
}
.app-icon-settings:before {
  content: map.get($studysmarter-icon-font-map, 'settings');
}
.app-icon-share-android:before {
  content: map.get($studysmarter-icon-font-map, 'share-android');
}
.app-icon-share-ios:before {
  content: map.get($studysmarter-icon-font-map, 'share-ios');
}
.app-icon-share:before {
  content: map.get($studysmarter-icon-font-map, 'share');
}
.app-icon-shield-tick:before {
  content: map.get($studysmarter-icon-font-map, 'shield-tick');
}
.app-icon-simplify:before {
  content: map.get($studysmarter-icon-font-map, 'simplify');
}
.app-icon-slash-circle:before {
  content: map.get($studysmarter-icon-font-map, 'slash-circle');
}
.app-icon-slide-in:before {
  content: map.get($studysmarter-icon-font-map, 'slide-in');
}
.app-icon-slide-out:before {
  content: map.get($studysmarter-icon-font-map, 'slide-out');
}
.app-icon-smartphone:before {
  content: map.get($studysmarter-icon-font-map, 'smartphone');
}
.app-icon-smiley-bad:before {
  content: map.get($studysmarter-icon-font-map, 'smiley-bad');
}
.app-icon-smiley-good:before {
  content: map.get($studysmarter-icon-font-map, 'smiley-good');
}
.app-icon-smiley-medium:before {
  content: map.get($studysmarter-icon-font-map, 'smiley-medium');
}
.app-icon-smiley-very-well:before {
  content: map.get($studysmarter-icon-font-map, 'smiley-very-well');
}
.app-icon-sort:before {
  content: map.get($studysmarter-icon-font-map, 'sort');
}
.app-icon-spaced-repetition:before {
  content: map.get($studysmarter-icon-font-map, 'spaced-repetition');
}
.app-icon-sparkles-filled:before {
  content: map.get($studysmarter-icon-font-map, 'sparkles-filled');
}
.app-icon-sparkles:before {
  content: map.get($studysmarter-icon-font-map, 'sparkles');
}
.app-icon-special-character:before {
  content: map.get($studysmarter-icon-font-map, 'special-character');
}
.app-icon-star-fill:before {
  content: map.get($studysmarter-icon-font-map, 'star-fill');
}
.app-icon-star-half-fill:before {
  content: map.get($studysmarter-icon-font-map, 'star-half-fill');
}
.app-icon-star:before {
  content: map.get($studysmarter-icon-font-map, 'star');
}
.app-icon-statistics-fill:before {
  content: map.get($studysmarter-icon-font-map, 'statistics-fill');
}
.app-icon-statistics:before {
  content: map.get($studysmarter-icon-font-map, 'statistics');
}
.app-icon-step-back:before {
  content: map.get($studysmarter-icon-font-map, 'step-back');
}
.app-icon-stop-circle:before {
  content: map.get($studysmarter-icon-font-map, 'stop-circle');
}
.app-icon-strikethrough:before {
  content: map.get($studysmarter-icon-font-map, 'strikethrough');
}
.app-icon-structure:before {
  content: map.get($studysmarter-icon-font-map, 'structure');
}
.app-icon-subject-agriculture:before {
  content: map.get($studysmarter-icon-font-map, 'subject-agriculture');
}
.app-icon-subject-anthropology:before {
  content: map.get($studysmarter-icon-font-map, 'subject-anthropology');
}
.app-icon-subject-architecture:before {
  content: map.get($studysmarter-icon-font-map, 'subject-architecture');
}
.app-icon-subject-arts:before {
  content: map.get($studysmarter-icon-font-map, 'subject-arts');
}
.app-icon-subject-biology:before {
  content: map.get($studysmarter-icon-font-map, 'subject-biology');
}
.app-icon-subject-business:before {
  content: map.get($studysmarter-icon-font-map, 'subject-business');
}
.app-icon-subject-chemistry:before {
  content: map.get($studysmarter-icon-font-map, 'subject-chemistry');
}
.app-icon-subject-computer-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-computer-sciences');
}
.app-icon-subject-earth-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-earth-sciences');
}
.app-icon-subject-economics:before {
  content: map.get($studysmarter-icon-font-map, 'subject-economics');
}
.app-icon-subject-education:before {
  content: map.get($studysmarter-icon-font-map, 'subject-education');
}
.app-icon-subject-engineering-technology:before {
  content: map.get($studysmarter-icon-font-map, 'subject-engineering-technology');
}
.app-icon-subject-english:before {
  content: map.get($studysmarter-icon-font-map, 'subject-english');
}
.app-icon-subject-environmental-studies-forestry:before {
  content: map.get($studysmarter-icon-font-map, 'subject-environmental-studies-forestry');
}
.app-icon-subject-family-consumer-science:before {
  content: map.get($studysmarter-icon-font-map, 'subject-family-consumer-science');
}
.app-icon-subject-fill:before {
  content: map.get($studysmarter-icon-font-map, 'subject-fill');
}
.app-icon-subject-french:before {
  content: map.get($studysmarter-icon-font-map, 'subject-french');
}
.app-icon-subject-geography:before {
  content: map.get($studysmarter-icon-font-map, 'subject-geography');
}
.app-icon-subject-german:before {
  content: map.get($studysmarter-icon-font-map, 'subject-german');
}
.app-icon-subject-history:before {
  content: map.get($studysmarter-icon-font-map, 'subject-history');
}
.app-icon-subject-human-physical-performance-recreation:before {
  content: map.get($studysmarter-icon-font-map, 'subject-human-physical-performance-recreation');
}
.app-icon-subject-interdisciplinary-studies:before {
  content: map.get($studysmarter-icon-font-map, 'subject-interdisciplinary-studies');
}
.app-icon-subject-journalism-media-studies-communication:before {
  content: map.get($studysmarter-icon-font-map, 'subject-journalism-media-studies-communication');
}
.app-icon-subject-languages-cultures:before {
  content: map.get($studysmarter-icon-font-map, 'subject-languages-cultures');
}
.app-icon-subject-latin:before {
  content: map.get($studysmarter-icon-font-map, 'subject-latin');
}
.app-icon-subject-law:before {
  content: map.get($studysmarter-icon-font-map, 'subject-law');
}
.app-icon-subject-library-museum-studies:before {
  content: map.get($studysmarter-icon-font-map, 'subject-library-museum-studies');
}
.app-icon-subject-literature:before {
  content: map.get($studysmarter-icon-font-map, 'subject-literature');
}
.app-icon-subject-logic:before {
  content: map.get($studysmarter-icon-font-map, 'subject-logic');
}
.app-icon-subject-mathematics:before {
  content: map.get($studysmarter-icon-font-map, 'subject-mathematics');
}
.app-icon-subject-medicine:before {
  content: map.get($studysmarter-icon-font-map, 'subject-medicine');
}
.app-icon-subject-military-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-military-sciences');
}
.app-icon-subject-music:before {
  content: map.get($studysmarter-icon-font-map, 'subject-music');
}
.app-icon-subject-other:before {
  content: map.get($studysmarter-icon-font-map, 'subject-other');
}
.app-icon-subject-philosophy:before {
  content: map.get($studysmarter-icon-font-map, 'subject-philosophy');
}
.app-icon-subject-physics:before {
  content: map.get($studysmarter-icon-font-map, 'subject-physics');
}
.app-icon-subject-political-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-political-sciences');
}
.app-icon-subject-psychology:before {
  content: map.get($studysmarter-icon-font-map, 'subject-psychology');
}
.app-icon-subject-public-administration:before {
  content: map.get($studysmarter-icon-font-map, 'subject-public-administration');
}
.app-icon-subject-religion-divinity:before {
  content: map.get($studysmarter-icon-font-map, 'subject-religion-divinity');
}
.app-icon-subject-social-work:before {
  content: map.get($studysmarter-icon-font-map, 'subject-social-work');
}
.app-icon-subject-sociology:before {
  content: map.get($studysmarter-icon-font-map, 'subject-sociology');
}
.app-icon-subject-space-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-space-sciences');
}
.app-icon-subject-spanish:before {
  content: map.get($studysmarter-icon-font-map, 'subject-spanish');
}
.app-icon-subject-sports:before {
  content: map.get($studysmarter-icon-font-map, 'subject-sports');
}
.app-icon-subject-systems-sciences:before {
  content: map.get($studysmarter-icon-font-map, 'subject-systems-sciences');
}
.app-icon-subject-transportation:before {
  content: map.get($studysmarter-icon-font-map, 'subject-transportation');
}
.app-icon-subject:before {
  content: map.get($studysmarter-icon-font-map, 'subject');
}
.app-icon-subscript:before {
  content: map.get($studysmarter-icon-font-map, 'subscript');
}
.app-icon-summaries-fill:before {
  content: map.get($studysmarter-icon-font-map, 'summaries-fill');
}
.app-icon-summaries:before {
  content: map.get($studysmarter-icon-font-map, 'summaries');
}
.app-icon-superscript:before {
  content: map.get($studysmarter-icon-font-map, 'superscript');
}
.app-icon-swipe-left:before {
  content: map.get($studysmarter-icon-font-map, 'swipe-left');
}
.app-icon-swipe-right:before {
  content: map.get($studysmarter-icon-font-map, 'swipe-right');
}
.app-icon-switch-horizontal:before {
  content: map.get($studysmarter-icon-font-map, 'switch-horizontal');
}
.app-icon-table-cell-option:before {
  content: map.get($studysmarter-icon-font-map, 'table-cell-option');
}
.app-icon-table-cell-style:before {
  content: map.get($studysmarter-icon-font-map, 'table-cell-style');
}
.app-icon-table-column:before {
  content: map.get($studysmarter-icon-font-map, 'table-column');
}
.app-icon-table-default:before {
  content: map.get($studysmarter-icon-font-map, 'table-default');
}
.app-icon-table-footer:before {
  content: map.get($studysmarter-icon-font-map, 'table-footer');
}
.app-icon-table-header:before {
  content: map.get($studysmarter-icon-font-map, 'table-header');
}
.app-icon-table-row:before {
  content: map.get($studysmarter-icon-font-map, 'table-row');
}
.app-icon-table-style:before {
  content: map.get($studysmarter-icon-font-map, 'table-style');
}
.app-icon-tablet:before {
  content: map.get($studysmarter-icon-font-map, 'tablet');
}
.app-icon-tag:before {
  content: map.get($studysmarter-icon-font-map, 'tag');
}
.app-icon-tags:before {
  content: map.get($studysmarter-icon-font-map, 'tags');
}
.app-icon-tap:before {
  content: map.get($studysmarter-icon-font-map, 'tap');
}
.app-icon-teacher:before {
  content: map.get($studysmarter-icon-font-map, 'teacher');
}
.app-icon-text-color:before {
  content: map.get($studysmarter-icon-font-map, 'text-color');
}
.app-icon-text-magic:before {
  content: map.get($studysmarter-icon-font-map, 'text-magic');
}
.app-icon-text-size:before {
  content: map.get($studysmarter-icon-font-map, 'text-size');
}
.app-icon-text:before {
  content: map.get($studysmarter-icon-font-map, 'text');
}
.app-icon-textbooks:before {
  content: map.get($studysmarter-icon-font-map, 'textbooks');
}
.app-icon-thumbs-down:before {
  content: map.get($studysmarter-icon-font-map, 'thumbs-down');
}
.app-icon-thumbs-up:before {
  content: map.get($studysmarter-icon-font-map, 'thumbs-up');
}
.app-icon-time:before {
  content: map.get($studysmarter-icon-font-map, 'time');
}
.app-icon-to-download:before {
  content: map.get($studysmarter-icon-font-map, 'to-download');
}
.app-icon-todo:before {
  content: map.get($studysmarter-icon-font-map, 'todo');
}
.app-icon-topic-magic:before {
  content: map.get($studysmarter-icon-font-map, 'topic-magic');
}
.app-icon-touch-and-hold:before {
  content: map.get($studysmarter-icon-font-map, 'touch-and-hold');
}
.app-icon-trash:before {
  content: map.get($studysmarter-icon-font-map, 'trash');
}
.app-icon-trello:before {
  content: map.get($studysmarter-icon-font-map, 'trello');
}
.app-icon-trending-up:before {
  content: map.get($studysmarter-icon-font-map, 'trending-up');
}
.app-icon-trophies:before {
  content: map.get($studysmarter-icon-font-map, 'trophies');
}
.app-icon-underline:before {
  content: map.get($studysmarter-icon-font-map, 'underline');
}
.app-icon-undo:before {
  content: map.get($studysmarter-icon-font-map, 'undo');
}
.app-icon-unfollow:before {
  content: map.get($studysmarter-icon-font-map, 'unfollow');
}
.app-icon-university:before {
  content: map.get($studysmarter-icon-font-map, 'university');
}
.app-icon-unlock-fill:before {
  content: map.get($studysmarter-icon-font-map, 'unlock-fill');
}
.app-icon-unlock:before {
  content: map.get($studysmarter-icon-font-map, 'unlock');
}
.app-icon-unpin:before {
  content: map.get($studysmarter-icon-font-map, 'unpin');
}
.app-icon-upload:before {
  content: map.get($studysmarter-icon-font-map, 'upload');
}
.app-icon-user-fill:before {
  content: map.get($studysmarter-icon-font-map, 'user-fill');
}
.app-icon-user:before {
  content: map.get($studysmarter-icon-font-map, 'user');
}
.app-icon-vertical-scroll:before {
  content: map.get($studysmarter-icon-font-map, 'vertical-scroll');
}
.app-icon-video-game:before {
  content: map.get($studysmarter-icon-font-map, 'video-game');
}
.app-icon-video:before {
  content: map.get($studysmarter-icon-font-map, 'video');
}
.app-icon-volume-fill:before {
  content: map.get($studysmarter-icon-font-map, 'volume-fill');
}
.app-icon-volume:before {
  content: map.get($studysmarter-icon-font-map, 'volume');
}
.app-icon-wallet:before {
  content: map.get($studysmarter-icon-font-map, 'wallet');
}
.app-icon-zoom-in-gesture:before {
  content: map.get($studysmarter-icon-font-map, 'zoom-in-gesture');
}
.app-icon-zoom-in:before {
  content: map.get($studysmarter-icon-font-map, 'zoom-in');
}
.app-icon-zoom-out-gesture:before {
  content: map.get($studysmarter-icon-font-map, 'zoom-out-gesture');
}
.app-icon-zoom-out:before {
  content: map.get($studysmarter-icon-font-map, 'zoom-out');
}
