@use '@stsm/styles/fonts';
@use '@stsm/styles/tokens' as tokens;
@use '@stsm/styles/colors/static-colors';
@use '@stsm/styles/dark-theme-specials' as dark-theme-specials;
@use '@stsm/styles/mixins/dark-mode.mixins' as dark-mode-mixin;
@use '@stsm/styles/mixins/scrollbar-styles.mixin' as scrollbar-styles-mixin;
@use './overrides/froala-colors';
@use '@stsm/styles/redesign-colors' as redesign-colors;

// Official Redesign tokens
@use '@stsm/styles/colors/light-theme-variables';
@use '@stsm/styles/colors/dark-theme-variables';
@use '@stsm/styles/colors/theme-color-util' as theme-color-util;
@use '@stsm/styles/shadow-tokens';
@use '@stsm/styles/blur-tokens';
@use '@stsm/styles/focus-ring-tokens';
@use '@stsm/styles/spacing-tokens';
@use '@stsm/styles/radius-tokens';
@use '@stsm/styles/typography-tokens';

@use '@stsm/styles/icon-font/studysmarter-icon-font'; // import shared icon font
@use '@stsm/styles/ion-variables' as ion-variables;

@use '@ionic/angular/css/core.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/normalize.css';

@use '@stsm/styles/grid-layout';
@use '@stsm/styles/default-responsive-inset';
@use '@stsm/styles/ion-page-styles';

@use '@stsm/styles/ion-overrides';

@use './main';
@use '@stsm/styles/ui-input-styles';
@use '@stsm/styles/toast-styles';

@use './print-styles';
@use './overrides/froala';
@use './overrides/material';

@use '@stsm/styles/theme-util-styles';
@use '@stsm/styles/typography-classes';

body {
  @include static-colors.light-theme-variables;
  @include ion-variables.colors;

  @include tokens.deprecated-font-size-variables;
  @include tokens.icon-size-variables;
  @include tokens.deprecated-font-weight-variables;
  @include tokens.font-family-variables;
  @include shadow-tokens.deprecated-box-shadows-light-theme;

  @include spacing-tokens.spacing-tokens;
  @include radius-tokens.radius-tokens;
  @include typography-tokens.typography-tokens;
  @include focus-ring-tokens.focus-ring-tokens;
  @include shadow-tokens.shadow-tokens;
  @include blur-tokens.blur-tokens;
  @include light-theme-variables.light-theme-variables;
  @include redesign-colors.var-fixes-and-extensions;
  @include theme-color-util.defaultThemeVariables;
}

@include dark-mode-mixin.global {
  @include static-colors.dark-theme-variables;
  @include shadow-tokens.deprecated-box-shadows-dark-theme;
  @include dark-theme-specials.implementations;
  @include froala-colors.froalaColors; // for now only in dark-theme

  @include ion-variables.colorsDarkTheme;

  @include dark-theme-variables.dark-theme-variables;
  @include redesign-colors.dark-var-fixes-and-extensions;

  .overlay-backdrop {
    background: var(--color-legacy-background-fade-backdrop-bold);
  }
}

// set all elements to Source Sans 3
* {
  font-family: var(--font-family);
  font-variant-numeric: lining-nums;
}

span {
  font: inherit;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden; // IE-10 fix
}

// root font size (only impacts REM usage) -> handy scale as 1 REM = 10px
html {
  font-size: 62.5%; // for default browser setting, 62.5% = 10px; Use REM values to support accessibility
}

// needed default font size -> expected to be 16px by several existing elements
body {
  font-size: var(--font-size-body-small);
  color: var(--color-text-primary);
  background: var(--color-bg-primary);
  line-height: 1.4;

  @include scrollbar-styles-mixin.scrollbar-styles;
}

a a:hover {
  text-decoration: none !important;
  color: var(--color-fg-interactive-default);
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: var(--color-fg-interactive-default);
}

iframe {
  border: 0;
}

.overlay-pane {
  inset: 0;
  width: 100% !important;
}

.overlay-backdrop {
  background: var(--color-legacy-background-fade-backdrop-default);
}

.text-align {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.header-toolbar {
  height: 50px !important;

  .title {
    margin-left: 20px;
  }
}

@media (hover: hover) {
  .animated-icon {
    color: var(--color-fg-tertiary);
    transition:
      transform 0.2s,
      filter 0.2s;

    &:hover {
      transform: scale(1.3);
      filter: brightness(85%);
      cursor: pointer;
    }
  }
}

@media (hover: none) {
  .animated-icon {
    color: var(--color-fg-tertiary);
  }
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.primary-title {
  margin: 0 !important;
  color: var(--color-text-secondary);
  font-size: var(--font-size-body-normal);
}

.icon-title {
  font-weight: var(--font-weight-normal);
  padding-top: 16px;
  padding-bottom: 16px;
  display: inline-flex;
  vertical-align: middle;
  flex-wrap: wrap;

  .icon {
    height: 40px;
    width: 40px;
    margin-right: 12px;
  }

  h3 {
    padding-top: 4px;
  }
}

.ng-resizable-handle.ng-resizable-e {
  z-index: 1 !important;
  cursor: col-resize !important;
}

.edit-flashcard-dialog-panel {
  border-radius: var(--radius-md);
}

// Hide Google Recaptcha BadgeModel
.grecaptcha-badge {
  display: none !important;
}

.one-liner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-liner {
  // css to limit the number of lines to 2 (https://css-tricks.com/almanac/properties/l/line-clamp/)
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.three-liner {
  // css to limit the number of lines to 3 (https://css-tricks.com/almanac/properties/l/line-clamp/)
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

div.print-dialog-hidden {
  display: none;
}

.pipe-highlighted {
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
}

span.highlight {
  background: var(--color-utility-success-100);
}

span.partial-highlight {
  background: var(--color-utility-yellow-200);
}

div.interstitial-ad-modal {
  width: clamp(480px, 50%, 760px);
  border-radius: var(--radius-xl);
  overflow: hidden;
}

// These styles are not applied when being defined in the component scss for some reason
ion-searchbar {
  &.subject-search {
    --background: var(--color-bg-primary);

    padding-top: 0;
    padding-bottom: 0;
    padding-inline: var(--spacing-md) var(--spacing-md);
    height: 100%;

    .searchbar-input-container {
      font-size: inherit;

      input {
        font-size: inherit;
        padding-inline-start: var(--spacing-4xl) !important;
      }

      .searchbar-clear-icon,
      .searchbar-search-icon {
        width: var(--icon-size-sm);
      }
    }
  }
}

/**
* Style overrides for necessary for the MatDatepicker
 */
mat-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  div.mat-mdc-form-field-flex {
    align-items: center;
  }

  .mdc-line-ripple {
    &::after,
    &::before {
      border-bottom-width: 0 !important;
    }
  }
}
