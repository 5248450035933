/**
 * This file contains static colors for e.g. partners, social login buttons etc.
 */

@mixin light-theme-variables {
  // Partner colors
  --clr-hemmer: #009cb4;
  --clr-hemmer-gradient: linear-gradient(to right, var(--clr-hemmer), var(--clr-hemmer));
  --clr-hemmer-background: mix(var(--clr-hemmer), #fff, 10%);

  // social login colors
  --color-bg-social-button: white;
  --color-fg-apple-button: black;

  // todo - remove these after ai branding is merged into main
  --color-assistant-blue: #0040c1;
  --color-assistant-cyan: #2ce;
  --color-assistant-gradient: linear-gradient(
    to right,
    var(--color-assistant-blue),
    var(--color-assistant-cyan),
    var(--color-assistant-blue)
  );

  --color-bg-gradient-brand-top: var(--color-utility-blue-dark-200);
  --color-bg-gradient-brand-dashboard: linear-gradient(to bottom, var(--color-bg-gradient-brand-top), var(--color-bg-body) 50%);
  --color-bg-gradient-brand: linear-gradient(to bottom, var(--color-utility-blue-dark-200), var(--color-bg-body) 85%);
  --color-bg-gradient-brand_alt: linear-gradient(180deg, var(--color-utility-blue-dark-400) 0%, var(--color-bg-body) 75%);
  --color-bg-gradient-brand-modal: linear-gradient(to bottom, var(--color-utility-blue-dark-200), var(--color-bg-modal) 85%);

  --color-featured-gradient-stable: linear-gradient(45deg, #4b73ff 0%, #7cf7ff 100%);

  --color-premium-gradient: linear-gradient(45deg, var(--color-premium-gradient-start), var(--color-premium-gradient-end));
  --color-premium-gradient-stable: linear-gradient(
    45deg,
    var(--color-premium-gradient-stable-start),
    var(--color-premium-gradient-stable-end)
  );
  --color-premium-gradient-gold: linear-gradient(
    180deg,
    var(--color-premium-gradient-gold-stable-start),
    var(--color-premium-gradient-gold-stable-end)
  );
  --color-premium-gradient-darkblue: linear-gradient(180deg, #071130 0%, #1638a4 100%);
  --color-bg-modal-premium: linear-gradient(135deg, #ccf2ff 0%, #dce1fb 49%, #eee5ff 100%);
  --color-premium-gradient-onboarding-accent: linear-gradient(180deg, #2970ff 0%, #dbe6fd 100%);

  // ai colors
  $ai-color-1: #00bfff;
  $ai-color-2: #516aec;
  $ai-color-3: #ae7cff;
  $ai-gradient-angle: 165deg;

  --color-ai-gradient: linear-gradient(#{$ai-gradient-angle}, #{$ai-color-1}, #{$ai-color-2}, #{$ai-color-3});
  --color-ai-gradient-horizontal: linear-gradient(90deg, #{$ai-color-1}, #{$ai-color-2}, #{$ai-color-3});
  --color-ai-gradient-circle: radial-gradient(circle, #{$ai-color-1}, #{$ai-color-2}, #{$ai-color-3});
  --color-ai-gradient-conic: conic-gradient(#{$ai-color-1}, #{$ai-color-2}, #{$ai-color-3}, #{$ai-color-1});
  --color-ai-gradient-subtle: linear-gradient(
    #{$ai-gradient-angle},
    #{rgba($ai-color-1, 0.2)},
    #{rgba($ai-color-2, 0.2)},
    #{rgba($ai-color-3, 0.2)}
  );
  --color-ai-gradient-card: linear-gradient(135deg, #{rgba($ai-color-1, 0.04)}, #{rgba($ai-color-2, 0.04)}, #{rgba($ai-color-3, 0.04)});
  --color-ai-gradient-subtle-circle: radial-gradient(
    circle,
    #{rgba($ai-color-1, 0.2)},
    #{rgba($ai-color-2, 0.2)},
    #{rgba($ai-color-3, 0.2)}
  );
  --color-ai-gradient-subtle-conic: conic-gradient(
    #{rgba($ai-color-1, 0.2)},
    #{rgba($ai-color-2, 0.2)},
    #{rgba($ai-color-3, 0.2)},
    #{rgba($ai-color-1, 0.2)}
  );

  // Conversational Onboarding
  --color-onboarding-card-gradient:
    linear-gradient(180deg, var(--color-bg-primary) 51.5%, var(--color-alpha-white-40) 100%), var(--color-alpha-white-40);

  // Streak colors
  --color-bg-streak-gradient: linear-gradient(to bottom, var(--color-utility-warning-100), transparent);
  --color-button-streak-gradient:
    linear-gradient(180deg, var(--color-alpha-white-100) 0%, var(--color-utility-warning-50) 100%), var(--color-bg-primary_alt);

  // Dashboard footer gradient
  --color-bg-footer-gradient: linear-gradient(
    to bottom,
    transparent 0%,
    var(--color-alpha-white-100) 20%,
    var(--color-alpha-white-100) 100%
  );

  // fun stuff
  --color-rainbow-gradient-500: linear-gradient(
    45deg,
    var(--color-utility-fuchsia-500),
    var(--color-utility-pink-500),
    var(--color-utility-orange-500),
    var(--color-utility-yellow-500),
    var(--color-utility-green-light-500),
    var(--color-utility-teal-500),
    var(--color-utility-blue-dark-500),
    var(--color-utility-purple-500)
  );
  --color-rainbow-gradient-200: linear-gradient(
    45deg,
    var(--color-utility-fuchsia-200),
    var(--color-utility-pink-200),
    var(--color-utility-orange-200),
    var(--color-utility-yellow-200),
    var(--color-utility-green-light-200),
    var(--color-utility-teal-200),
    var(--color-utility-blue-dark-200),
    var(--color-utility-purple-200)
  );

  // Affiliate
  --color-affiliate-gradient: linear-gradient(
    315deg,
    var(--color-affiliate-gradient-stable-start) 0%,
    var(--color-affiliate-gradient-stable-mid) 50%,
    var(--color-affiliate-gradient-stable-end) 100%
  );
  --color-affiliate-gradient-subtle: linear-gradient(
    315deg,
    rgba(0, 78, 235, 20%) 0%,
    rgba(41, 112, 255, 20%) 50%,
    rgba(0, 220, 180, 20%) 100%
  );
}

@mixin dark-theme-variables {
  --color-bg-gradient-brand-top: var(--color-utility-blue-dark-50);
  --color-bg-gradient-brand-dashboard: linear-gradient(to bottom, var(--color-bg-gradient-brand-top), var(--color-bg-body) 50%);
  --color-bg-gradient-brand: linear-gradient(to bottom, var(--color-utility-blue-dark-50), var(--color-bg-body) 85%);
  --color-bg-gradient-brand_alt: linear-gradient(180deg, var(--color-utility-blue-dark-200) 0%, var(--color-bg-body) 75%);
  --color-bg-gradient-brand-modal: linear-gradient(to bottom, var(--color-utility-blue-dark-50), var(--color-bg-modal) 85%);

  --color-bg-modal-premium: linear-gradient(135deg, #123c52 0%, #222b4e 49%, #342f52 100%);
  --color-premium-gradient-onboarding-accent: linear-gradient(180deg, #2970ff 0%, #081f51 100%);

  --color-bg-streak-gradient: linear-gradient(to bottom, var(--color-utility-warning-50), transparent);
}
